body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  background-color: #fcfcfc;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}


.auth-form-container {
  margin-top: 20%;
}

@media screen and (min-width: 992px) {
  .auth-form-container {
    margin-top: 50%;
  }
}

.auth-form-container .auth-form {
  background-color: white;
  -webkit-box-shadow: 14px 14px 48px 3px rgba(0,0,0,0.3);
  -moz-box-shadow: 14px 14px 48px 3px rgba(0,0,0,0.3);
  box-shadow: 14px 14px 48px 3px rgba(0,0,0,0.3);

  border-radius: 5px;
  padding: 20px;
}



.auth-form-container .auth-form div.input-group input {
  padding-left: 10px;
  border-left: 0;
}

.auth-form-container .auth-form div.input-group input:focus {
  outline: 0 !important;
  -webkit-box-shadow: none;
  box-shadow: none;

  border: 1px solid #ced4da;;
  border-left: 0;
}

div.form-links p a {
  color: rgba(255, 255, 255, 0.5);
  color: rgba(255, 255, 255, 0.5) !important;
}

.form-control::placeholder { color: #aaaaaf; } /* Chrome, Firefox, Opera*/
:-ms-input-placeholder.form-control { color: #aaaaaf; }  /* Internet Explorer*/
.form-control::-ms-input-placeholder { color: #aaaaaf; }  /* Microsoft Edge*/

.auth-form-container .auth-form div.input-group .input-group-text {
  background-color: white;
  border-right: 0;
}

.auth-form-container .auth-form div.input-group span.img-icon {
  display: block;
  position: relative;

  width: 20px;
  height: 20px;
  opacity: 0.3;

  background-size: 20px 20px;
  background-position: center center;
}

.auth-form-container .auth-form div.input-group span.img-icon.envelope {
  background-image: url('/app/images/envelope.png');
}

.auth-form-container .auth-form div.input-group span.img-icon.key {
  background-image: url('/app/images/key.png');
}


@media screen and (min-width: 768px) {

  div.LoginPage nav, div.SignupPage nav {
    background-color: transparent !important;
  }
}


@-webkit-keyframes bounce {
  from,
  20%,
  53%,
  80%,
  to {
    -webkit-animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
    animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }

  40%,
  43% {
    -webkit-animation-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
    animation-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
    -webkit-transform: translate3d(-30px, 0, 0);
    transform: translate3d(-30px, 0, 0);
  }

  70% {
    -webkit-animation-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
    animation-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
    -webkit-transform: translate3d(-15px, 0, 0);
    transform: translate3d(-15px, 0, 0);
  }

  90% {
    -webkit-transform: translate3d(-4px, 0, 0);
    transform: translate3d(-4px, 0, 0);
  }
}

@keyframes bounce {
  from,
  20%,
  53%,
  80%,
  to {
    -webkit-animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
    animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }

  40%,
  43% {
    -webkit-animation-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
    animation-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
    -webkit-transform: translate3d(-30px, 0, 0);
    transform: translate3d(-30px, 0, 0);
  }

  70% {
    -webkit-animation-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
    animation-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
    -webkit-transform: translate3d(-15px, 0, 0);
    transform: translate3d(-15px, 0, 0);
  }

  90% {
    -webkit-transform: translate3d(-4px, 0, 0);
    transform: translate3d(-4px, 0, 0);
  }
}

.bounce {
  -webkit-animation: bounce ease-in 1.25s 1;
  animation: bounce ease-in 1.25s 1;

}



span.img-icon {
  display: inline-block;
  position: relative;

  width: 15px;
  height: 15px;

  top: 2px;
  background-size: 15px 15px;
  background-position: center center;
}

span.img-icon.icon-wrench {
  background-image: url('/app/images/wrench.png');
}