div.LoginPage {

  text-align: center;
  min-height: 100vh;

  background-image: url('/app/images/auth-photo.jpg');
  background: linear-gradient(rgba(19, 19, 19, 0.7), rgba(19, 19, 30, 0.75)), url('/app/images/auth-photo.jpg');

  background-size: cover;
  background-repeat: no-repeat;
}
